import { ChevronRightIcon } from '@heroicons/react/20/solid'
import {useState} from 'react';

export default function Footer() {

    const [result, setResult] = useState("");

    const onSubmit = async (event) => {
        event.preventDefault();
        setResult("Sending....");
        const formData = new FormData(event.target);

        formData.append("access_key", "96643538-9fab-4713-9825-95490cd79053");

        const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData
        }).then((res) => res.json());

        if (res.success) {
            console.log("Success", res);
            setResult(res.message);
        } else {
            console.log("Error", res);
            setResult(res.message);
        }
    };


  return (

    <>


    <div 
        className="relative isolate overflow-hidden  text-left flex mx-auto max-w-7xl pt-32"
        // style={{backgroundImage: 'url("laptop2.png")', backgroundPosition: "bottom center", backgroundSize: "contain",backgroundRepeat:"no-repeat"}}
    >

        
        <div className="text-left w-full">
          <div className="mx-auto max-w-2xl text-left ">
            <h2 className="text-base font-semibold leading-7 text-indigo-400">No server? No problem.</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Contact us today to learn more</p>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              We specialize in distributed, permissionless technologies, emabling scaling without the typical constraints imposed by centralized web2.0 infrastructure.
            </p>
          </div>
        </div>

        
        <div className='w-full' id="contactform">


            <form onSubmit={onSubmit}>
                <input type="hidden" name="access_key" value="" />
                
                
                <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                        <div>
                            <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-white">
                            First name
                            </label>
                            <div className="mt-2.5">
                            <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                autoComplete="given-name"
                                className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                            />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-white">
                            Last name
                            </label>
                            <div className="mt-2.5">
                            <input
                                type="text"
                                name="last-name"
                                id="last-name"
                                autoComplete="family-name"
                                className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                            />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="email" className="block text-sm font-semibold leading-6 text-white">
                            Email
                            </label>
                            <div className="mt-2.5">
                            <input
                                type="email"
                                name="email"
                                id="email"
                                autoComplete="email"
                                className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                            />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-white">
                            Phone number
                            </label>
                            <div className="mt-2.5">
                            <input
                                type="tel"
                                name="phone-number"
                                id="phone-number"
                                autoComplete="tel"
                                className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                            />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="message" className="block text-sm font-semibold leading-6 text-white">
                            Message
                            </label>
                            <div className="mt-2.5">
                            <textarea
                                name="message"
                                id="message"
                                rows={4}
                                className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                defaultValue={''}
                            />
                            </div>
                        </div>
                        <div class="h-captcha" data-captcha="true"></div>

                    </div>
                    <div className="mt-8 flex justify-end">
                        <button type="submit" 
                            className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                        >
                        Send Message
                        </button>
                    </div>
                </div>

                <div className="text-white">
                    {result}
                </div>
            </form>

            

        </div>


        
            

    </div>

    <div className="mx-auto max-w-7xl px-6 lg:px-8 py-8 pt-20">
          <div className="mx-auto sm:text-center">
            <p className="mt-6 text-md leading-8 text-gray-300 text-shadow">
              Copyright {new Date().getFullYear()} © K3A Labs Limited. All rights reserved.
            </p>
          </div>
        </div>

    </>
  )

}
