import {
    ArrowPathIcon,
    CloudArrowUpIcon,
    Cog6ToothIcon,
    FingerPrintIcon,
    LockClosedIcon,
    ServerIcon,
  } from '@heroicons/react/20/solid'
  
  const features = [
    {
      name: 'Back-End Execution and Program Logic',
      description: 'Wwe engineer the backbone of your web3 project. Our expert team ensures robust back-end execution and program logic that paves the way for seamless user experiences and efficient operations.',
      icon: CloudArrowUpIcon,
    },
    {
      name: 'Secure Program Deployment and Management',
      description: 'Security is at the heart of our approach. We deploy and manage your programs in a highly secure environment, utilizing cutting-edge technologies to safeguard your web3 assets.',
      icon: LockClosedIcon,
    },
    {
      name: 'Front-end Application Development',
      description: 'Crafting visually stunning and highly interactive front-end applications is our forte. Elevate user engagement with captivating designs and intuitive interfaces developed by K3A Labs.',
      icon: ArrowPathIcon,
    },
    {
      name: 'User Experience (UX) Design',
      description: 'User satisfaction is paramount. We prioritize exceptional user experiences through thoughtful UX design, ensuring your web3 application is a delight to interact with.',
      icon: FingerPrintIcon,
    },
    {
      name: 'Architecting On-, Off-Chain, and Hybrid Solutions',
      description: 'We are pioneers in architecting versatile solutions, whether on-chain, off-chain, or a strategic blend of both. Tailored to your needs, we optimize efficiency and scalability.',
      icon: Cog6ToothIcon,
    },
    {
      name: 'Bespoke Support and Monitoring',
      description: 'Our commitment extends beyond development. Benefit from personalized support and vigilant monitoring to ensure your web3 project runs flawlessly, around the clock.',
      icon: ServerIcon,
    },
  ]
  
  export default function Cards() {
    return (

      <>

        

         <div className="bg-gradient-to-b from-black to-gray-900  pb-24 sm:pb-32 ">

          <div className="relative overflow-hidden -mt-12 -mb-32">
              <div className="max-w-7xl mx-auto opacity-80 ">
                <img
                  src="laptop4.png"
                  alt="App screenshot"
                  // className="mb-[-12%]"
                  // width={2432}
                  // height={1442}
                />
      
                
              </div>
            </div>  

            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl sm:text-center pt-8">
                <h2 className="text-base font-semibold leading-7 text-indigo-400">Our service offering</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">How can we help?</p>
                <p className="mt-6 text-lg leading-8 text-gray-300">
                  We specialize in distributed, permissionless technologies, emabling scaling without the typical constraints imposed by centralized web2.0 infras
                </p>
              </div>
            </div>

            <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
              <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-white">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-500" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>



            </div>
      </>
     
    )
  }
  