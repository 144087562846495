import { ChevronRightIcon } from '@heroicons/react/20/solid'
import {useState } from 'react';

export default function Hero() {

    const [showModal, setShowModal] = useState(false);
    const toggleShowModal = () => {
        setShowModal(!showModal)
    }

  return (
    <>

    
    <div 
        className="relative isolate overflow-hidden bg-gray-900 text-center flex h-screen 
        bg-[linear-gradient(to_right_bottom,rgba(99,102,241,0.8),rgba(4,47,46,0.8)),url('background.png')]"
    >   

           
            <div className="m-auto max-w-7xl px-6 pb-24 sm:pb-32 lg:py-40 justify-center">

                <div className="m-auto max-w-3xl lg:max-w-2xl lg:pt-8 align-center">
                
                    <span className="text-3xl font-semibold text-white">
                        K3A Labs
                    </span>
                    {/* <div className="mt-24 sm:mt-32 lg:mt-16">
                        <a href="#" className="inline-flex space-x-6">
                        <span className="rounded-full bg-indigo-500/10 px-3 py-1 text-sm font-semibold leading-6 text-indigo-400 ring-1 ring-inset ring-indigo-500/20">
                            What's new
                        </span>
                        <span className="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-300">
                            <span>Just shipped v1.0</span>
                            <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                        </span>
                        </a>
                    </div> */}
                    <h1 className="mt-10 text-4xl font-bold tracking-tight text-white sm:text-6xl">
                        Unlocking the potential of web3.0 for your business
                    </h1>
                    <p className="mt-6 text-lg leading-8 text-gray-300 font-semibold">
                        K3A Labs, Ireland's Web3.0 Specialist
                    </p>
                    <div className="mt-10 items-center gap-x-6 text-center">
                        
                            <button 
                                type="button" 
                                class="rounded-md font-semibold px-4 py-2 text-white transition ease-in-out duration-300 bg-gradient-to-r from-green-400 to-blue-500 hover:from-green-600 hover:to-blue-600 "
                                style={{"cursor":"pointer"}} 
                                onClick={() => {
                                    const anchor = document.querySelector('#contactform')
                                    anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
                                }}
                            >
                                Contact Us
                            </button>
            
                    </div>
                    <div className="h-[10vh] items-center gap-x-6 text-center">
                     
                    </div>
                </div>
                
            </div>

            
            

    </div>


    </>
  )

}
